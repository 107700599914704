import React from "react";
import { ScreenCloud } from "../../ScreenCloudReactApp";
import Site from "../../components/Sites/Site";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

interface Props {
  sc: ScreenCloud;
}

export const AppContainer = (props: Props): JSX.Element => {
  console.log("Viewer - AppContainer - props.sc.config", props.sc.config);

  return (
    <ErrorBoundary {...props}>
      <Site {...props} />
    </ErrorBoundary>
  );
};
