export const config = {
  type: "secure",
  url: "",
  siteId: "8a022faf-66e0-45fd-b84a-7127f946be28",
  refreshIntervalSeconds: 5
};

export const context = {
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiU1RVRElPX1VTRVIiLCJlbWFpbCI6InJvYmVydC5uZXdtYW5Ac2NyZWVuY2xvdWQuaW8iLCJvcmdfaWQiOiI4OGRmNjM2ZS1lZDI5LTQ1MTUtODM4OS0zNTc2NDY2ZWNmODIiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDUuZ29vZ2xldXNlcmNvbnRlbnQuY29tLy1JWlVlU3EtcXViZy9BQUFBQUFBQUFBSS9BQUFBQUFBQUFBQS9BQ0hpM3JlWTlCOUlXUlA0M2wtN3F5QXRLT0dHOGw5UVdnL3Bob3RvLmpwZyIsInVzZXJfaWQiOiIxYjkxNTRiNC1iY2JmLTQwMjYtOGFiZi0zMmVjMGI3YWI4NDMiLCJpc19vd25lciI6dHJ1ZSwib3JnX25hbWUiOiJSb2IgVGVzdCIsInByb3ZpZGVyIjoiZ29vZ2xlLW9hdXRoMiIsImNvbm5lY3Rpb24iOiJnb29nbGUtb2F1dGgyIiwiZ2l2ZW5fbmFtZSI6IlJvYmVydCIsImZhbWlseV9uYW1lIjoiTmV3bWFuIiwiaWF0IjoxNTg1MjQ0Mzk3LCJleHAiOjE5ODUyNDYxOTcsImF1ZCI6InBvc3RncmFwaHFsIiwiaXNzIjoiaHR0cHM6Ly9zaWduYWdlLnNjcmVlbi5jbG91ZCJ9.asYDbkEMIgLBs5UQsWvk_qsk0Yu2J7UWgV5oMNm_8yE"
};
