import React, { Component, useContext, ReactNode } from "react";
import { IBridge, IBridgeMessage, IMessage } from "@screencloud/app-core";
import {
  AppMessages,
  EditorMessages,
  EditorPlayerMessages,
  IAppConfig,
  MixedPlayer,
  IAppFiles
} from "@screencloud/signage-sdk";
import { config as developmentConfig } from "./config.development";
import { config as stagingConfig } from "./config.staging";
import {
  Initialize,
  Start
} from "@screencloud/signage-sdk/build/app/AppPlayerMessages";
import { RequestConfigUpdate } from "@screencloud/signage-sdk/build/editor/EditorMessages";
import { datadogLogs, Logger } from "@datadog/browser-logs";

// NB - Will split this file out into multiple pieces when we solve code sharing properly.

type ScreenCloudLoggerType = "debug" | "warn" | "info" | "error";

export interface ScreenCloudLogger {
  debug: <DataType extends {}>(message: string, data?: DataType) => void;
  info: <DataType extends {}>(message: string, data?: DataType) => void;
  warn: <DataType extends {}>(message: string, data?: DataType) => void;
  error: <DataType extends {}>(message: string, data?: DataType) => void;
}

function createScreenCloudLogger<DataType extends {}>(
  datadogLogger: Logger,
  logMapper?: (
    type: ScreenCloudLoggerType,
    message: string,
    data?: Record<string, unknown>
  ) => [string, Record<string, unknown>]
) {
  return {
    debug: (message: string, data?: DataType) => {
      if (logMapper) {
        datadogLogger.debug(...logMapper("debug", message, data));
      } else {
        datadogLogger.debug(message, data);
      }
    },
    info: (message: string, data?: DataType) => {
      if (logMapper) {
        datadogLogger.info(...logMapper("info", message, data));
      } else {
        datadogLogger.info(message, data);
      }
    },
    warn: (message: string, data?: DataType) => {
      if (logMapper) {
        datadogLogger.warn(...logMapper("warn", message, data));
      } else {
        datadogLogger.warn(message, data);
      }
    },
    error: (message: string, data?: DataType) => {
      if (logMapper) {
        datadogLogger.error(...logMapper("error", message, data));
      } else {
        datadogLogger.error(message, data);
      }
    }
  };
}

export enum LogLevel {
  Off = 0,
  Error = 1,
  Warning = 2,
  Info = 3,
  Debug = 4
}

export interface Theme {
  primaryColor: { [key: string]: string };
  textOnPrimary: { [key: string]: string };
  textOnSecondary: { [key: string]: string };
  secondaryColor: { [key: string]: string };
  headingFont?: Font;
  bodyFont?: Font;
  id: string;
  name: string;
}

export interface ScreenData {
  [key: string]: string;
}

interface Font {
  family: string;
  url: string;
}

export interface OldTheme {
  colorBgPrimary: string;
  colorTextBody: string;
  colorTextHeading: string;
  colorTextLink: string;
  fontBody: string;
  fontHeading: string;
  fontUrlBody: string;
  fontUrlHeading: string;
  id: string;
  name: string;
}

export type Platform =
  | "studio"
  | "android"
  | "firetv"
  | "chrome"
  | "ios"
  | "embeddable"
  | "msteams";

export interface DeviceConfig {
  platform?: Platform;
  model?: string;
  version?: string;
}

export interface Files {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface AppFile {
  _ref: AppImageFile;
}

export interface AppImageFile {
  id: string;
  type: string;
  url: string;
  size: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnknownAppConfig = { [prop: string]: any };
export type EmitFinished = (payload?: AppMessages.Finished.Payload) => void;
export type EmitPreloaded = () => void;
export type EmitConfigUpdateAvailable = () => void;
export type OnRequestConfigUpdate = (
  handler: EditorMessages.RequestConfigUpdate.Handler
) => void;
export type RequestFile = (mimeTypes: string) => Promise<AppFile | null>;
export type RequestFiles = (mimeTypes: string) => Promise<AppFile[] | null>;

/**
 * In old SDK requestAuthToken can take an "audience" value.
 * Only 1 app uses this, so making optional and it should be removed if no use case.
 */
export type RequestAuthToken = (
  payload?: EditorPlayerMessages.RequestAuthToken.Payload
) => Promise<EditorPlayerMessages.RequestAuthToken.Response>;

export interface ScreenCloud {
  appStarted: boolean;
  context: {
    theme: Theme;
    screenData: ScreenData | null;
    userInteractionEnabled: boolean;
    loggingLevel?: LogLevel;
    timezone?: string;
  };
  appId: string;
  orgId: string;
  screenId?: string;
  viewerUrl?: string;
  appInstanceId?: string;
  spaceId?: string;
  device?: DeviceConfig;
  filesByAppInstanceId?: IAppFiles;
  durationMs?: number;
  durationElapsedMs?: number;
  logger?: ScreenCloudLogger;
  config: UnknownAppConfig;
  userPermissions?: string[];
  emitFinished: EmitFinished;
  emitPreloaded: EmitPreloaded;
  emitConfigUpdateAvailable: EmitConfigUpdateAvailable;
  onRequestConfigUpdate: OnRequestConfigUpdate;
  requestAuthToken: RequestAuthToken;
  requestFile: RequestFile;
  requestFiles: RequestFiles;
}

declare global {
  interface Window {
    Cypress?: {};
    __initAppWithConfig?: (configData: IAppConfig) => void;
    __startApp?: () => void;
  }
}

/* eslint-disable @typescript-eslint/no-empty-function */
const initialSc = {
  appStarted: false,
  context: {
    theme: {
      primaryColor: {},
      textOnPrimary: {},
      textOnSecondary: {},
      secondaryColor: {},
      id: "",
      name: ""
    },
    screenData: {},
    userInteractionEnabled: false
  },
  durationElapsedMs: 0,
  appId: "",
  orgId: "",
  config: {},
  userPermissions: ["*"],
  emitFinished: () => {},
  emitPreloaded: () => {},
  emitConfigUpdateAvailable: () => {},
  onRequestConfigUpdate: () => {},
  requestAuthToken: () => {
    return Promise.resolve({ authToken: "abc123", expiresAt: 999999999 });
  },
  logger: undefined,
  requestFile: () => {
    return Promise.resolve({
      _ref: {
        id: "",
        type: "",
        url: "",
        size: ""
      }
    });
  },
  requestFiles: () => {
    return Promise.resolve([
      {
        _ref: {
          id: "",
          type: "",
          url: "",
          size: ""
        }
      }
    ]);
  }
};
/* eslint-enable @typescript-eslint/no-empty-function */

export const ScreenCloudContext = React.createContext<ScreenCloud>(initialSc);

export const makeOldThemeFromNew = (newTheme: Theme): OldTheme => ({
  colorBgPrimary: newTheme.primaryColor["500"],
  colorTextBody: newTheme.textOnPrimary["500"],
  colorTextHeading: newTheme.textOnPrimary["500"],
  colorTextLink: newTheme.secondaryColor["500"],
  fontBody: (newTheme.bodyFont && newTheme.bodyFont.family) || "Default",
  fontHeading:
    (newTheme.headingFont && newTheme.headingFont.family) || "Default",
  fontUrlBody: (newTheme.bodyFont && newTheme.bodyFont.url) || "",
  fontUrlHeading: (newTheme.headingFont && newTheme.headingFont.url) || "",
  id: newTheme.id,
  name: newTheme.name
});

const initDataDog = (sc: ScreenCloud): Logger => {
  const {
    orgId,
    screenId,
    viewerUrl,
    spaceId,
    appInstanceId,
    appId,
    device
  } = sc;
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_TOKEN as string,
    datacenter: "us", // using only US datadog account for convenience right now
    env: process.env.REACT_APP_SC_ENV as string,
    forwardErrorsToLogs: true, // logs console.error logs, uncaught exceptions and network errors
    service: "apps"
  });
  datadogLogs.logger.addContext("screenId", screenId);
  datadogLogs.logger.addContext("orgId", orgId);
  datadogLogs.logger.addContext("viewerUrl", viewerUrl);
  datadogLogs.logger.addContext("spaceId", spaceId);
  datadogLogs.logger.addContext("instanceId", appInstanceId);
  datadogLogs.logger.addContext("appId", appId);
  datadogLogs.logger.addContext("device", device);

  return datadogLogs.logger;
};

class LocalBridge implements IBridge {
  isConnected = true;
  isConnecting = false;

  connect(): Promise<void> {
    return Promise.resolve();
  }

  disconnect(): Promise<void> {
    return Promise.resolve();
  }

  send(bridgeMessage: IBridgeMessage): void {
    console.log(`Local Bridge - Send`, bridgeMessage);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request(message: IMessage): Promise<any> {
    console.log(`Local Bridge - Request`, message);
    return Promise.resolve();
  }
}

// Are we running inside a Player?
// If so, the app will have been loaded inside an iFrame.
const isRunningInPlayer = (): boolean => {
  const top = window.opener || window.parent || window.top;
  return top !== window.self && !window.Cypress;
};

// Are we running inside the mocked player specifically?
// i.e. not the Next Player
const isRunningInMockedPlayer = (): boolean => {
  return (
    isRunningInPlayer() && window.location.search.includes("mode=mocked-player")
  );
};

// Are we running inside the mocked player specifically?
// i.e. not the Next Player AND a user has clicked the "Preview" button
const isRunningInMockedPlayerPreview = (): boolean => {
  return (
    isRunningInPlayer() &&
    window.location.search.includes("mode=preview-mocked-player")
  );
};

// Are we running inside the Next player?
// i.e. not the mocked Player
const isRunningInNextPlayer = (): boolean => {
  return isRunningInPlayer() && !isRunningInMockedPlayer();
};

// Is the app running locally in dev mode?
const isLocalDevMode = (): boolean => {
  return process.env.NODE_ENV === "development";
};

// Is the app running inside an E2E test?
// (i.e. production build, but not in a player)
const isE2ETest = (): boolean => {
  return !!window.Cypress;
};

// Convenience hook for function components.
export const useScreenCloud = (): ScreenCloud => {
  const screenCloudContext = useContext(ScreenCloudContext);

  if (screenCloudContext.appId === "") {
    throw Error("ScreenCloudContext is not initialized");
  }
  return screenCloudContext;
};

// Convenience hook for function components.
export const useTheme = (): Theme => {
  const screenCloudContext = useContext(ScreenCloudContext);

  if (screenCloudContext.appId === "") {
    throw Error("ScreenCloudContext is not initialized");
  }
  return screenCloudContext.context.theme;
};

interface Props {
  children: (sc: ScreenCloud) => JSX.Element;
  defaultTheme?: Theme;
  logMapper?: (
    type: string,
    message: string,
    data?: Record<string, unknown>
  ) => [string, Record<string, unknown>];
}

interface State {
  isInitialized: boolean;
  sc: ScreenCloud;
  player: MixedPlayer;
  initializePayload?: Initialize.Payload;
}

class ScreenCloudReactApp extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // If not running inside a player, we need to mimic the bridge to a player.
    const player = isRunningInPlayer()
      ? new MixedPlayer()
      : new MixedPlayer(undefined, new LocalBridge());

    this.state = {
      isInitialized: false,
      player,
      sc: initialSc
    };
  }

  componentDidMount(): void {
    this.initPlayerHandlers();
  }

  initPlayerHandlers = (): void => {
    const { player } = this.state;

    /**
     * Initialize the app with stub config data (for development + test)
     */
    const __initAppWithConfig = (config: UnknownAppConfig): void => {
      const initializeMessage = {
        type: "initialize",
        payload: {
          appId: "local-app-id",
          appInstanceId: "local-app-instance-id",
          authority: "local-authority",
          context: { loggingLevel: LogLevel.Off, screenData: { useUHD: "false" } },
          orgId: "local-org",
          spaceId: "local-space",
          state: {},
          config,
          filesByAppInstanceId: {
            nodes: [
              {
                metadata: {
                  key: "f3NQQTHiwQ86RsZQgvAm_Screencloud.png",
                  url:
                    "https://d3631rbjt5rv0e.cloudfront.net/98ae0d07-a2b3-4eb9-8a97-45fb5da56e53/originals/MYZOmUkR329GOcV40KMb_Kittyply_edit1.jpg",
                  size: 6951,
                  handle: "WFfSy1F5S7GN6z9dfsEw",
                  source: "local_file_system"
                },
                mimetype: "image/png",
                id: "123",
                source:
                  "https://d3631rbjt5rv0e.cloudfront.net/98ae0d07-a2b3-4eb9-8a97-45fb5da56e53/originals/MYZOmUkR329GOcV40KMb_Kittyply_edit1.jpg"
              },
              {
                metadata: {
                  key: "f3NQQTHiwQ86RsZQgvAm_Screencloud.png",
                  url:
                    "https://d3631rbjt5rv0e.cloudfront.net/98ae0d07-a2b3-4eb9-8a97-45fb5da56e53/originals/MYZOmUkR329GOcV40KMb_Kittyply_edit1.jpg",
                  size: 6951,
                  handle: "WFfSy1F5S7GN6z9dfsEw",
                  source: "local_file_system"
                },
                mimetype: "image/png",
                id: "12",
                source: "https://wallpapercave.com/wp/wp2046360.jpg"
              }
            ]
          },
          userPermissions: ["*"]
        }
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: TODO - Can SDK mark this method public instead of protected?
      player.receive(initializeMessage);
    };

    /**
     * Initialize the app with stub config data (for development + test)
     */
    const __startApp = (): void => {
      const startMessage: Start.Message = {
        type: "start"
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: TODO - Can SDK mark this method public instead of protected?
      player.receive(startMessage);
    };

    player.onInitialize(async (initializePayload: Initialize.Payload) => {
      console.log("Initialized with payload", initializePayload);
      const sc = this.makeScContext(initializePayload);
      let screenCloudLogger: ScreenCloudLogger | undefined = undefined;
      // Turn DataDog logs on when loggingLevel is greater than 0
      if (sc.context.loggingLevel && sc.context.loggingLevel > 0) {
        if (!this.state.sc.logger) {
          const datadogLogger = initDataDog(sc);
          screenCloudLogger = createScreenCloudLogger(
            datadogLogger,
            this.props.logMapper
          );
          let storage;
          if ("storage" in navigator && "estimate" in navigator.storage) {
            const estimate = await navigator.storage.estimate();
            storage = `Using ${estimate.usage &&
              estimate.usage / 1e6} out of ${estimate.quota &&
              estimate.quota / 1e6} MB.`;
          }
          screenCloudLogger.info("App initialized with payload", {
            config: sc.config,
            theme: sc.context.theme,
            userPermissions: sc.userPermissions,
            screenData: sc.context.screenData,
            durationMs: sc.durationMs,
            durationElapsedMs: sc.durationElapsedMs,
            filesByAppInstanceId:
              sc.filesByAppInstanceId && sc.filesByAppInstanceId.nodes,
            storage: storage
          });
        } else {
          this.state.sc.logger.info("App initialized with payload");
        }

        if (
          process.env.NODE_ENV === "production" &&
          "serviceWorker" in navigator
        ) {
          let count = 0;
          navigator.serviceWorker.addEventListener("message", event => {
            if (event.data) {
              const data = JSON.parse(event.data.data);
              switch (event.data.type) {
                case "SC_ERROR": {
                  if (
                    data.message &&
                    data.message.includes("Storage Quota Error")
                  ) {
                    // Only log Storage Quota Error once
                    if (count === 0) {
                      console.log("Storage Quota Error logged");
                      screenCloudLogger &&
                        screenCloudLogger.error(data.message, data);
                    }
                    count = count + 1;
                  }

                  // Do not log error that was caused from datadog
                  // Storage quota errors will not have requestUrls
                  if (
                    data.requestUrl &&
                    !data.requestUrl.includes("logs.datadoghq")
                  ) {
                    screenCloudLogger &&
                      screenCloudLogger.error(data.message, data);
                  }
                  break;
                }
                case "SC_INFO":
                  break;
                case "SC_DEBUG":
                  break;
                case "SC_WARN":
                  break;
              }
            }
          });
        }
      }

      this.setState(
        {
          initializePayload,
          isInitialized: true,
          sc: {
            ...sc,
            logger: screenCloudLogger
          }
        },
        () => {
          player.emitInitialized();
        }
      );
    });

    player.onStart(() => {
      console.log("App started");
      player.emitStarted();
      this.setState({
        sc: { ...this.state.sc, appStarted: true }
      });
    });

    player.onFinish(() => {
      console.log("App finished");
      player.emitFinished();
    });

    // TODO - Modify development.js without impacting Git
    player
      .connect()
      .then(() => {
        // Local dev = Dev config or data from e2e test.
        // Production = No stub data in real player. Staging config or e2e test data otherwise.
        if (!isE2ETest() && !isRunningInMockedPlayerPreview()) {
          if (isLocalDevMode()) {
            __initAppWithConfig(developmentConfig);
            __startApp();
          } else if (!isRunningInNextPlayer()) {
            __initAppWithConfig(stagingConfig);
            __startApp();
          }
        }
      })
      .catch(e => {
        console.log("Connect failed", e);
      });

    // Expose publicly for tests to call at runtime.
    window.__initAppWithConfig = __initAppWithConfig;
    window.__startApp = __startApp;
  };

  /* Map file objects to format studio expects 
    i.e File objs replaced with file.type and file.ids
  */
  mapConfigFromAppToStudio = (config: UnknownAppConfig): UnknownAppConfig => {
    for (const i in config) {
      if (typeof config[i] == "object") {
        if (i === "_ref") {
          config[i] = {
            type: config[i].type,
            id: config[i].id
          };
        }

        this.mapConfigFromAppToStudio(config[i]);
      }
    }
    return config;
  };

  /* Update the config object with the files from
     filesByAppInstanceId by matching ids
  */
  addFilesToConfig = (
    config: UnknownAppConfig,
    files: Files
  ): UnknownAppConfig => {
    for (const i in config) {
      if (typeof config[i] == "object") {
        if (i === "_ref") {
          const file = files.find((file: Files) => file.id === config[i].id);
          if (file) {
            config[i] = {
              id: file.id,
              type: "file",
              url: file.source,
              size: file.metadata.size
            };
          }
        }
        this.addFilesToConfig(config[i], files);
      }
    }
    return config;
  };

  /* Map config to format that apps expect i.e associated file
    data added to config object
  */
  mapConfigFromStudioToApp = (
    config: UnknownAppConfig,
    files: IAppFiles | undefined
  ): UnknownAppConfig => {
    if (files && files.nodes) {
      const filesArr = files["nodes"];
      const newConfig = this.addFilesToConfig(config, filesArr);
      return newConfig;
    }
    return config;
  };

  onRequestConfigUpdate = (
    appHandler: EditorMessages.RequestConfigUpdate.Handler
  ): void => {
    // Called by sdk - with type save or preview
    const internalHandler = (
      payload: RequestConfigUpdate.Payload
    ): Promise<RequestConfigUpdate.Response> => {
      // Call the app's requestConfigUpdate handler to get the app's view of its config.
      return appHandler(payload).then(
        (payload: RequestConfigUpdate.Response) => {
          const config = payload.config;
          const mappedConfig = this.mapConfigFromAppToStudio(config);
          return Promise.resolve({
            ...payload,
            config: mappedConfig
          });
        }
      );
    };

    this.state.player.onRequestConfigUpdate(internalHandler);
  };

  wrapFile = (file: Files): AppFile => {
    return {
      _ref: {
        id: file.id,
        type: "file",
        url: file.source,
        size: file.metadata.size
      }
    };
  };

  // Return a single file
  requestFile = async (mimeTypes: string): Promise<AppFile | null> => {
    const result = await this.state.player.requestFiles({
      mimeTypes,
      multiSelect: false
    });
    if (result && result.media && result.media.length > 0) {
      const file = result.media[0];
      return this.wrapFile(file);
    }
    return null;
  };

  // Return an array of files
  requestFiles = async (mimeTypes: string): Promise<AppFile[] | null> => {
    const result = await this.state.player.requestFiles({
      mimeTypes,
      multiSelect: true
    });

    if (result && result.media) {
      const files = result.media;
      const wrappedFiles = files.map((file: Files) => {
        return this.wrapFile(file);
      });
      return wrappedFiles;
    }
    return null;
  };

  /**
   * Make the ScreenCloud object to be given to the main app code.
   * Combines Init payload with handlers the app may use.
   */
  makeScContext = (payload: Initialize.Payload): ScreenCloud => {
    const { player } = this.state;

    const playerRequestAuthToken = player.requestAuthToken.bind(player);

    const configFromStudio = payload.config;
    const configForApp = this.mapConfigFromStudioToApp(
      configFromStudio,
      payload.filesByAppInstanceId
    );
    return {
      ...payload,
      config: configForApp,
      appStarted: false,
      context: {
        ...payload.context,
        theme: payload.context.theme || this.props.defaultTheme,
        screenData: payload.context.screenData || null,
        userInteractionEnabled: payload.context.userInteractionEnabled || false
      },
      // Player Methods
      emitFinished: player.emitFinished.bind(player),
      emitPreloaded: player.emitPreloaded.bind(player),
      requestAuthToken: payload => {
        if (!isRunningInPlayer() && isLocalDevMode()) {
          const localToken = process.env.REACT_APP_LOCAL_DEV_TOKEN;
          if (localToken) {
            return Promise.resolve({
              authToken: localToken,
              expiresAt: 1898593356
            });
          }
        }

        const audience = payload?.audience || "sc-app";
        return playerRequestAuthToken({
          audience
        });
      },
      // Editor Methods
      emitConfigUpdateAvailable: player.emitConfigUpdateAvailable.bind(player),
      onRequestConfigUpdate: this.onRequestConfigUpdate,
      requestFile: this.requestFile,
      requestFiles: this.requestFiles
    };
  };

  render(): ReactNode {
    const { isInitialized, sc } = this.state;

    if (!isInitialized || !sc) {
      return (
        <div
          style={{
            display: "none"
          }}
        >
          The app has not received an Initialize message.
        </div>
      );
    }

    return (
      <ScreenCloudContext.Provider value={sc}>
        {this.props.children(sc)}
      </ScreenCloudContext.Provider>
    );
  }
}

export default ScreenCloudReactApp;
