import React, { ReactNode } from "react";
import { ScreenCloud } from "../../ScreenCloudReactApp";
import { SiteConfig, ScreenConfig } from "../Sites/Site";
import * as Sentry from "@sentry/browser";
import {
  getRenderedMedia,
  GetMediaRequest,
  GetMediaResponse,
  RendererStatus
} from "../../utils/MediaGatewayClient";
import "./SecureSite.css";
import { appConfig } from "../../appConfig";
import { Loader } from "../Loader/Loader";
import errorIcon from "../../assets/error.svg";
import tzlookup from "tz-lookup";

interface Token {
  authToken: string;
  expiresAt: number;
}

interface State {
  preloading?: string;
  rendering?: string;
  error: any;
  jobError: string;
  insufficientQuota: boolean;
  polling: boolean;
  renderStartUnixTimestamp: number;
  previousTimestamp: number;
  showLastUpdatedTime: boolean;
  lastUpdatedDivStyle: string;
  screenTimezone: string;
}

export enum SiteType {
  BASIC = "BASIC",
  BASIC_INTERNAL = "BASIC_INTERNAL",
  CLOUD = "CLOUD",
  CLOUD_INTERNAL = "CLOUD_INTERNAL",
  SECURE = "SECURE",
  SECURE_INTERNAL = "SECURE_INTERNAL"
}

export interface Props {
  sc: ScreenCloud;
  siteConfig: SiteConfig;
  screenConfig: ScreenConfig;
}

const MAX_AUTH_TOKEN_ATTEMPTS = 3;
const AUTH_TOKEN_DELAY_MODIFIER = 2000;

const PRELOAD_MINIMUM_REFRESH_IN_SECONDS = 300;
const PRELOAD_WARMUP_IN_SECONDS = 60;
const SCROLL_DELAY_IN_SECONDS = 2;
const INSUFFICIENT_QUOTA_DISPLAY_IN_SECONDS = 10;

const POLLING_TIMEOUT_CLOUD_IN_SECONDS = 5;
const POLLING_TIMEOUT_SECURE_IN_SECONDS = 2400;

export default class SecureSite extends React.Component<Props, State> {
  private authTokenAttempts = 0;
  private authTokenTimeout?: number;
  private preloadTimer: NodeJS.Timeout | undefined;
  private intervalTimer: NodeJS.Timeout | undefined;
  private delayScrollTimer: NodeJS.Timeout | undefined;
  private pollingTimeoutTimer: NodeJS.Timeout | undefined;
  private token: Token = { authToken: "", expiresAt: 0 };
  private img?: HTMLImageElement;
  private isRequestOutstanding = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      rendering: "",
      error: undefined,
      jobError: "",
      insufficientQuota: false,
      polling: false,
      // Set initial "start render" timestamp. Compared to retrieved snapshot timestamps.
      // Used to decide whether to display "last updated" message or not
      renderStartUnixTimestamp: Math.round(Date.now() / 1000),
      previousTimestamp: 0,
      showLastUpdatedTime: false,
      lastUpdatedDivStyle: "last-refresh-timestamp",
      screenTimezone: "Europe/London"
    };

    console.debug("Init SecureSite constructor with state: ", this.state);
  }

  /**
   * Avoiding async life cycle method by calling the asynchronous function and handling the Promise returned.
   */
  componentDidMount(): void {
    console.debug(
      "Component Mounted - Start render timestamp set to: ",
      this.state.renderStartUnixTimestamp
    );

    try {
      this.beginCloudRendering().catch(rejection => {
        console.debug("BeginCloudRendering() promise rejection");
        this.setSentryExtras();

        console.error(
          `Promise rejection during mounting: ${rejection.toString()}`
        );
        this.setState({ error: rejection });
      });
    } catch (error) {
      console.debug("beginCloudRendering() error");
      this.setSentryExtras();

      console.error(`Error during mounting SecureSite: ${error}`);
      this.setState({ error });
      throw error;
    }
  }

  componentWillUnmount(): void {
    console.debug("Calling componentWillUnmount()");

    this.clearIntervals();

    if (this.authTokenTimeout) {
      window.clearTimeout(this.authTokenTimeout);
    }
  }

  setSentryExtras = (): void => {
    Sentry.setExtras({
      siteId: this.props.siteConfig.siteId,
      siteType: this.props.siteConfig.type,
      screenId: this.props.screenConfig.screenId,
      screenData: this.props.screenConfig.screenData
    });
  };

  clearIntervals = (): void => {
    console.debug(">>> clearIntervals Secure");
    if (this.preloadTimer) clearInterval(this.preloadTimer);
    if (this.intervalTimer) clearInterval(this.intervalTimer);
    if (this.delayScrollTimer) clearInterval(this.delayScrollTimer);
    if (this.pollingTimeoutTimer) clearInterval(this.pollingTimeoutTimer);
    console.debug("<<< clearIntervals Secure");
  };

  beginCloudRendering = async (): Promise<void> => {
    console.debug(">>> beginCloudRendering Secure");

    try {
      this.token = await this.getAuthToken();
    } catch (error) {
      this.setSentryExtras();
      return Promise.reject(
        `unable to retrieve token ${JSON.stringify(error)}`
      );
    }

    this.setTimezone(this.props.screenConfig);

    console.debug(
      "SecureSite beginCloudRendering() current state: ",
      this.state
    );

    console.debug("beginCloudRendering this.token", this.token);

    await this.pollForNextRender();
    this.displayRender();

    const refreshInternal = this.props.siteConfig.refreshIntervalSeconds
      ? this.props.siteConfig.refreshIntervalSeconds
      : parseInt(appConfig.secureSiteDefaultRefreshInterval);

    await this.setupIntervals(refreshInternal);

    console.debug("<<< beginCloudRendering Secure");
  };

  setTimezone = (screenConfig: ScreenConfig): void => {
    let screenTimezone = "";

    if (screenConfig) {
      const { screenData, timezone } = screenConfig;

      if (timezone) {
        screenTimezone = timezone;
      } else if (
        screenData &&
        screenData.sc_latitude &&
        screenData.sc_longitude
      ) {
        // Try timezone lookup by lat and long
        const tz = tzlookup(
          parseInt(screenData.sc_latitude),
          parseInt(screenData.sc_longitude)
        );

        if (tz) {
          screenTimezone = tz;
        }
      }
    }

    if (screenTimezone !== "") {
      console.info(`Indentified Screen Timezone as ${screenTimezone}`);

      this.setState({
        screenTimezone
      });
    }
  };

  startPollingTimer = async (): Promise<void> => {
    this.setState({ polling: true });
    let pollingTimeout;
    if (
      this.props.siteConfig.type === SiteType.CLOUD ||
      this.props.siteConfig.type === SiteType.CLOUD_INTERNAL
    ) {
      console.debug("starting polling cloud");
      pollingTimeout = POLLING_TIMEOUT_CLOUD_IN_SECONDS * 1000;
    } else {
      console.debug("starting polling secure");
      pollingTimeout = POLLING_TIMEOUT_SECURE_IN_SECONDS * 1000;
    }
    this.pollingTimeoutTimer = setTimeout(() => {
      this.setState({ polling: false });
    }, pollingTimeout);
  };

  pollForNextRender = async (): Promise<void> => {
    if (this.isRequestOutstanding) {
      console.debug(
        "Previous render request still outstanding - skipping pollForNextRender"
      );
      return;
    }

    console.debug(">>> pollForNextRender Secure");

    await this.startPollingTimer();

    let mediaResponse: GetMediaResponse = await this.requestRender();

    console.debug("Media response: ", mediaResponse);

    // If a snapshot is present, show it regardless of status
    if (mediaResponse.base64Image && mediaResponse.base64Image?.length > 0) {
      console.debug("Rendering Base64Image");
      this.cacheRender(mediaResponse);
    }

    // Check if the returned image timestamp from S3 is fresh
    if (
      mediaResponse.renderTimestamp &&
      mediaResponse.renderTimestamp >= this.state.renderStartUnixTimestamp
    ) {
      // Snapshot is fresh -> Hide "Last updated" message
      this.setState({ showLastUpdatedTime: false });
    } else {
      // Showing an older image due to error, or waiting for renderer to start up
      // Show "last updated" message
      this.setState({ showLastUpdatedTime: true });
    }

    // Check for job error:
    // (previous successful snapshot retrieved, but currently erroring when trying to run journey)
    if (mediaResponse.jobError) {
      // Show last updated timestamp
      this.setState({ showLastUpdatedTime: true });

      // Change style of the "Last updated" message to be red
      this.setState({ jobError: mediaResponse.jobError });
      this.setState({
        lastUpdatedDivStyle: "last-refresh-timestamp-job-error"
      });
    } else {
      this.setState({ jobError: "" });
      this.setState({ lastUpdatedDivStyle: "last-refresh-timestamp" });
    }

    console.debug(
      "Initial start render timestamp: ",
      this.state.renderStartUnixTimestamp
    );
    console.debug("Latest snapshot timestamp: ", mediaResponse.renderTimestamp);
    console.debug(
      "Show 'Last updated' timestamp? : ",
      this.state.showLastUpdatedTime
    );

    if (mediaResponse.rendererStatus === RendererStatus.Starting) {
      do {
        await this.wait(1000);
        mediaResponse = await this.requestRender();
      } while (
        mediaResponse.rendererStatus === RendererStatus.Starting &&
        this.state.polling
      );
    }

    if (mediaResponse.rendererStatus === RendererStatus.InsufficientQuota) {
      this.setState({ insufficientQuota: true });
    } else if (
      mediaResponse.rendererStatus !== RendererStatus.NotModified &&
      mediaResponse.rendererStatus! !== RendererStatus.Rendering
    ) {
      if (!this.state.polling) {
        console.debug(
          "SecureSite !this.state.polling check passed. Throwing timeout error"
        );

        throw new Error(
          "Timeout: Unable to retrieve render from media gateway"
        );
      } else {
        console.debug("SecureSite Throwing renderError");

        // Throw if renderError
        // This will unmount SecureSite component, and propagate error to ErrorBoundary
        this.setState({ error: new Error(mediaResponse.renderError) });
        throw new Error(mediaResponse.renderError);
      }
    }

    console.log("Current state at end of pollForNextRender(): ", this.state);

    console.debug("<<< pollForNextRender Secure");
  };

  cacheRender = (renderResponse: GetMediaResponse): void => {
    console.debug(">>> cacheRender Secure");
    this.setState({ preloading: renderResponse.base64Image });
    this.setState({
      previousTimestamp: renderResponse.renderTimestamp
        ? renderResponse.renderTimestamp
        : 0
    });
    console.debug("<<< cacheRender Secure");
  };

  // Wrapper for getRenderedMedia, which lets other methods know
  // whether or not the latest request is still outstanding
  watchGetRenderedMedia = async (
    getMediaRequest: GetMediaRequest
  ): Promise<GetMediaResponse> => {
    this.isRequestOutstanding = true;

    const mediaResponse: GetMediaResponse = await getRenderedMedia(
      getMediaRequest
    );

    this.isRequestOutstanding = false;

    return mediaResponse;
  };

  requestRender = async (): Promise<GetMediaResponse> => {
    console.debug(">>> requestRender Secure");

    const getMediaRequest: GetMediaRequest = {
      siteId: this.props.siteConfig.siteId,
      screenId: this.props.screenConfig.screenId,
      screenData: this.props.screenConfig.screenData,
      viewportWidth: window.innerWidth,
      viewportHeight: this.props.siteConfig.scrollFactor
        ? undefined
        : window.innerHeight,
      viewportScale: this.props.siteConfig.zoom || 1,
      authToken: this.token.authToken,
      timeZone: this.props.siteConfig.timeZone, // Use this.state.screenTimezone to enable screen timezone usage
      previousTimestamp: this.state.previousTimestamp
    };

    const mediaResponse: GetMediaResponse = await this.watchGetRenderedMedia(
      getMediaRequest
    );

    console.debug("<<< requestRender Secure");
    return mediaResponse;
  };

  setupIntervals = (refreshIntervalSeconds: number): void => {
    console.debug(">>> setupIntervals Secure");

    // SECURE SITES:-
    // will be using dedicated renderers. if refresh interval < 5 mins just poll and display when available
    // if refresh > 5 mins start to preload the image 1 minute before into cache and then swap on interval

    // CLOUD SITES:-
    // will be using shared renderers so should just start polling at each interval and display as soon as render available

    const isSecure =
      this.props.siteConfig.type === SiteType.SECURE ||
      this.props.siteConfig.type === SiteType.SECURE_INTERNAL;
    const isPreloading =
      this.props.siteConfig.refreshIntervalSeconds &&
      this.props.siteConfig.refreshIntervalSeconds >=
        PRELOAD_MINIMUM_REFRESH_IN_SECONDS;

    const refreshIntervalMS = refreshIntervalSeconds * 1000;

    if (isSecure && isPreloading) {
      const preloadWarmupMS = PRELOAD_WARMUP_IN_SECONDS * 1000;
      console.debug("Setting up Preload");
      setTimeout(
        this.setupPreloadInterval,
        refreshIntervalMS - preloadWarmupMS,
        refreshIntervalMS
      );

      this.intervalTimer = setInterval(this.displayRender, refreshIntervalMS);
    } else {
      console.debug("Setting up load and display");
      this.intervalTimer = setInterval(
        this.loadAndDisplayRender,
        refreshIntervalMS
      );
    }

    console.debug("<<< setupIntervals Secure");
  };

  setupPreloadInterval = (refreshIntervalMS: number): void => {
    console.log("setting preload refreshIntervalM", refreshIntervalMS);
    this.preloadTimer = setInterval(this.pollForNextRender, refreshIntervalMS);
  };

  loadAndDisplayRender = async (): Promise<void> => {
    console.debug(">>> loadAndDisplayRender");
    await this.pollForNextRender();
    this.displayRender();
    console.debug("<<< loadAndDisplayRender");
  };

  displayRender = (): void => {
    console.debug(">>> displayRender");
    this.setState({ rendering: this.state.preloading });
  };

  getAuthToken = async (): Promise<any> => {
    do {
      try {
        ++this.authTokenAttempts;
        return await this.props.sc!.requestAuthToken();
      } catch (err) {
        if (this.authTokenAttempts === MAX_AUTH_TOKEN_ATTEMPTS) {
          this.setState(() => {
            throw err;
          });
          return;
        }

        await this.wait(this.authTokenAttempts * AUTH_TOKEN_DELAY_MODIFIER);
      }
    } while (this.authTokenAttempts < MAX_AUTH_TOKEN_ATTEMPTS);
  };

  wait = (ms: number): Promise<void> => {
    return new Promise(resolve => window.setTimeout(resolve, ms));
  };

  delayStartScroll = (): void => {
    console.debug("delayStartScroll appStarted", this.props.sc.appStarted);

    this.delayScrollTimer = setTimeout(() => {
      console.log("Start when image loaded");
      this.startAnimation();
    }, SCROLL_DELAY_IN_SECONDS * 1000);
  };

  startAnimation = (): void => {
    if (this.img) {
      console.debug("startAnimation this.img.height", this.img.height);
      console.debug(
        "startAnimation this.props.siteConfig.scrollFactor",
        this.props.siteConfig.scrollFactor
      );
      console.debug("startAnimation window.innerHeight", window.innerHeight);
      if (
        this.props.siteConfig.scrollFactor &&
        this.img.height > window.innerHeight
      ) {
        const scrollHeight = this.img.height - window.innerHeight;
        const scrollFactor = this.props.siteConfig.scrollFactor || 1;
        const pixelsPerSecond = 25 * scrollFactor;
        const animationTime = Math.ceil(scrollHeight / pixelsPerSecond) * 1000;

        let actualImageWidth;
        let offsetX = 0;
        if (this.props.siteConfig.zoom) {
          actualImageWidth = window.innerWidth * this.props.siteConfig.zoom;
          offsetX = (-1 * (actualImageWidth - window.innerWidth)) / 2;
        } else {
          actualImageWidth = window.innerWidth;
        }

        this.img.animate(
          [
            { transform: `translate(${offsetX}px, 0px)` },
            {
              transform: `translate(${offsetX}px, -${scrollHeight}px)`
            }
          ],
          animationTime
        );
      }
    }

    console.debug("starting animation");
  };

  endApp = (): void => {
    setTimeout(() => {
      this.props.sc.emitFinished();
    }, INSUFFICIENT_QUOTA_DISPLAY_IN_SECONDS * 1000);
  };

  parseUnixToTimestampString = (unixTimestamp: number): string => {
    const dateFromUnix: Date = new Date(unixTimestamp * 1000);
    const formattedDate = dateFromUnix.toLocaleDateString();
    const formattedTime = dateFromUnix.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
    return `Last updated: ${formattedDate} at ${formattedTime}`;
  };

  render(): ReactNode {
    let actualImageWidth;
    let offsetX = 0;
    const scrolling = !!this.props.siteConfig.scrollFactor;
    const zoomed = !!this.props.siteConfig.zoom;
    if (zoomed) {
      actualImageWidth = window.innerWidth * this.props.siteConfig.zoom!;
      offsetX = (-1 * (actualImageWidth - window.innerWidth)) / 2;
    } else {
      actualImageWidth = window.innerWidth;
    }

    if (this.state.error) {
      throw this.state.error;
    }

    if (this.state.insufficientQuota) {
      console.debug("render appStarted", this.props.sc.appStarted);
      if (this.props.sc.appStarted) {
        console.debug("ending app");
        this.endApp();
      }

      return (
        <div className={"img-container"} style={{ color: `#FFF` }}>
          <h1>You have insufficient quota to render this site</h1>
        </div>
      );
    } else if (!this.state.rendering) {
      return <Loader />;
    } else {
      return (
        <div className={"img-container-wrapper"}>
          <div className={"img-container"}>
            <img
              src={this.state.rendering}
              alt={"Secure Site Render"}
              onLoad={event => {
                console.log("iframe onLoad event = ", event);
                this.delayStartScroll();
              }}
              ref={img => (this.img = img || undefined)}
              style={{ transform: `translateX(${offsetX}px)` }}
              className={[
                scrolling ? "scrolling" : "non-scrolling",
                zoomed ? "zoomed" : "non-zoomed"
              ].join(" ")}
            />
          </div>
          {this.state.showLastUpdatedTime && (
            <div className={this.state.lastUpdatedDivStyle}>
              {this.state.jobError && (
                <img
                  src={errorIcon}
                  alt="error-icon.svg"
                  className={"last-refresh-timestamp-job-error-warning-icon"}
                />
              )}
              <div className={"last-refresh-timestamp-text"}>
                {this.parseUnixToTimestampString(this.state.previousTimestamp)}
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}
