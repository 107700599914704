import React, { ReactElement } from "react";

import Lottie from "./LottieWrapper";
import animation from "./sc-preloader-animation.json";
import "./Loader.css";

export const Loader = (): ReactElement<unknown> => {
  return (
    <div className="loader-wrapper">
      <div>
        <div>
          <Lottie
            options={{
              animationData: animation
            }}
          />
        </div>
        <div className="loader-text-container">
          <p className="loader-text">Loading your dashboard</p>
        </div>
      </div>
    </div>
  );
};
