import React, { useRef, useEffect, useState, ReactElement } from "react";
import lottie from "lottie-web/build/player/lottie_light";
import {
  AnimationConfig,
  AnimationItem,
  AnimationConfigWithData
} from "lottie-web";

export interface LottieOptions {
  animationData: any;
  loop?: boolean;
  autoplay?: boolean;
  rendererSettings?: AnimationConfig["rendererSettings"];
}

export interface LottileAnimationControl {
  [property: string]: [number, number];
}

export interface LottieProps {
  options: LottieOptions;
  animationControl?: LottileAnimationControl;
  height?: string | number;
  width?: string | number;
  title?: string;
}

const getSize = (value: number | string | undefined): string => {
  let size;

  if (typeof value === "number") {
    size = `${value}px`;
  } else {
    size = value || "100%";
  }

  return size;
};

const Lottie = (props: LottieProps): ReactElement<LottieProps> => {
  const { width, height, title, options: optionsProps } = props;
  const { loop, autoplay, animationData } = optionsProps;
  const [element, setElement] = useState<Element | null>(null);
  const animationRef = useRef<AnimationItem | undefined>(undefined);

  useEffect(() => {
    if (element) {
      const options: AnimationConfigWithData = {
        container: element,
        renderer: "svg",
        loop: loop !== false,
        autoplay: autoplay !== false,
        animationData
      };
      animationRef.current = lottie.loadAnimation(options);
      animationRef.current.play();
    }
    return (): void => {
      if (animationRef.current) {
        animationRef.current.destroy();
      }
    };
  });

  const lottieStyles = {
    width: getSize(width),
    height: getSize(height),
    overflow: "hidden",
    margin: "0 auto",
    outline: "none"
  };

  return (
    <div
      ref={(el): void => setElement(el)}
      style={lottieStyles}
      title={title}
    />
  );
};

export default Lottie;
